import { ApplicantInfoForm } from '@containers/long-app/containers/applicant-info/config/flow.config';
import { AssetsForm } from '@containers/long-app/containers/assets/config/flow.config';
import { BorrowerInfoForm } from '@containers/long-app/containers/borrowers-info/config/flow.config';
import { IncomesForm } from '@containers/long-app/containers/incomes/config/flow.config';
import { getPropertyFlow } from '@containers/long-app/containers/property/utils/property.utils';
import { CUSTOMER_PORTAL_ROUTES, ROUTES, API, LONG_APP_ROUTES } from '@models/enums';
import { FlowNode, FlowRoot } from '@models/flow.model';
import { FlowState } from '@models/flowState.model';
import {
  BorrowerType,
  DealPurpose,
  BorrowerClientDto,
  DetailedIncomeDto,
  MortgageApplicationClientDto,
  DetailedPropertyDto,
} from '@pinecorpca/evergreen';
import { constructQuestionTags } from 'hooks/helpers/tag.helper';
import get from 'lodash/get';
import pick from 'lodash/pick';

type CompletionProps = {
  completed: boolean;
  questionId?: string;
  progress?: number;
  total?: number;
};

type Section = {
  path: string;
  completed: boolean;
  hash?: string;
};

type FlowSection = {
  path: string;
  hash?: string;
  sections: Section[];
};

export const isSectionComplete = <T>(data: T, form: FlowRoot): CompletionProps => {
  let progress = 0;
  const total = Object.values(form).filter((value) => typeof value === 'object').length - 1;
  let question = form[form.root as string] as FlowNode;

  while (question) {
    const { next, api, dataPath, options, submitHandler } = question;
    const constructedTags = constructQuestionTags(question, data, false);

    const isNotComplete =
      !constructedTags.length || constructedTags.some(({ value }) => (typeof value === 'boolean' ? value == null : !value));

    if (isNotComplete) {
      return {
        completed: false,
        questionId: question.id,
        progress,
        total,
      };
    }
    progress++;

    let nextQuestionId = '';
    if (next === FlowState.NEXTSECTION) {
      return { completed: true, questionId: '', progress, total };
    }
    if (next === FlowState.SEPARATE) {
      const { fieldName } = question;
      const fieldValue = get(data[api as keyof T], fieldName as string);
      nextQuestionId = options?.find((o) => o.value === fieldValue)?.next as string;
      if (nextQuestionId === FlowState.NEXTSECTION) {
        return { completed: true, questionId: '', progress, total };
      }
    } else if (next === FlowState.DYNAMIC && submitHandler) {
      nextQuestionId = submitHandler(get(data, dataPath as string));
      if (nextQuestionId === FlowState.NEXTSECTION) {
        return { completed: true, questionId: '', progress, total };
      }
    } else {
      nextQuestionId = next as string;
    }
    question = form[nextQuestionId] as FlowNode;
  }
  return { completed: false, questionId: '', progress, total };
};

export const isPropertyCompletedHelper = (application: MortgageApplicationClientDto | undefined): CompletionProps => {
  const { dealPurpose, property } = { ...application };
  const propertyFlow = getPropertyFlow({
    dealPurpose,
  });
  return isSectionComplete(
    {
      [API.MORTGAGE_APPLICATION]: application,
      [API.PROPERTY]: property as DetailedPropertyDto,
    },
    propertyFlow
  );
};

export const isApplicantInfoCompletedHelper = (borrower: BorrowerClientDto | undefined): CompletionProps => {
  const { addressHistories } = { ...borrower };
  const currentAddress = addressHistories?.find((address) => address.isCurrent);
  const previousAddress = addressHistories?.find((address) => !address.isCurrent);
  return isSectionComplete(
    {
      [API.BORROWER]: borrower,
      [API.ADDRESS]: {
        current: currentAddress,
        previous: previousAddress,
      },
    },
    borrower?.borrowerType === BorrowerType.Primary ? ApplicantInfoForm : BorrowerInfoForm
  );
};

export const isAdditionalInfoCompletedHelper = (borrower: BorrowerClientDto): CompletionProps => {
  const minimumBorrowerInfo = pick(borrower, 'firstName', 'lastName', 'relationship', 'phoneNumber', 'dateOfBirth', 'maritalStatus');
  return { completed: Object.values(minimumBorrowerInfo).every(Boolean) };
};

export const isIncomesCompleteHelper = (borrower: BorrowerClientDto | undefined): CompletionProps => {
  const incomes = borrower?.incomes as DetailedIncomeDto[];
  return isSectionComplete(
    {
      [API.INCOMES]: incomes,
    },
    IncomesForm(borrower?.borrowerType)
  );
};

export const isAssetsCompletedHelper = (dealPurpose: DealPurpose, borrower: BorrowerClientDto | undefined): CompletionProps => {
  const isSwitchRefinance = [DealPurpose.SwitchTransfer, DealPurpose.Refinance].includes(dealPurpose as DealPurpose);
  return isSectionComplete(
    {
      [API.BORROWER]: borrower,
    },
    AssetsForm(borrower?.borrowerType, isSwitchRefinance)
  );
};

export const getLastCompletedSection = (application: MortgageApplicationClientDto | undefined): FlowSection => {
  const { locked, borrowers, id: applicationId } = { ...application };

  if (locked) {
    return {
      path: `/${ROUTES.CUSTOMER_PORTAL}/${applicationId}/${CUSTOMER_PORTAL_ROUTES.DASHBOARD}`,
      sections: [],
    };
  }
  const isAdditionalBorrowersCompleted = borrowers?.every((borrower) => isAdditionalInfoCompletedHelper(borrower).completed) ?? false;

  const { completed: isPropertyCompleted, questionId: propertyQuestionId } = isPropertyCompletedHelper(application);

  const [mainBorrower, ...coBorrowers] =
    borrowers
      ?.sort((a, b) => ((a?.creationDate ?? '') > (b?.creationDate ?? '') ? 1 : -1))
      .flatMap((borrower) => {
        const { completed: isApplicantInfoCompleted, questionId: infoQuestionId } = isApplicantInfoCompletedHelper(borrower);
        const { completed: isIncomesCompleted } = isIncomesCompleteHelper(borrower);
        const { completed: isAssetsCompleted } = isAssetsCompletedHelper(application?.dealPurpose as DealPurpose, borrower);
        return [
          {
            path: `/applications/${applicationId}/borrowers/${borrower.id}/${LONG_APP_ROUTES.APPLICANT_INFO}`,
            completed: isApplicantInfoCompleted,
            hash: infoQuestionId,
          },
          {
            path: `/applications/${applicationId}/borrowers/${borrower.id}/${LONG_APP_ROUTES.INCOMES}`,
            completed: isIncomesCompleted,
            hash: undefined,
          },
          {
            path: `/applications/${applicationId}/borrowers/${borrower.id}/${LONG_APP_ROUTES.ASSETS}`,
            completed: isAssetsCompleted,
            hash: propertyQuestionId,
          },
        ];
      }) || [];

  const sections = [
    {
      path: `/applications/${applicationId}/${LONG_APP_ROUTES.PROPERTY}`,
      completed: isPropertyCompleted,
      hash: propertyQuestionId,
    },
    mainBorrower,
    {
      path: `/applications/${applicationId}/${LONG_APP_ROUTES.ADDITIONAL_BORROWERS}`,
      completed: isAdditionalBorrowersCompleted,
      hash: undefined,
    },
    ...coBorrowers,
  ];

  const lastCompletedSection = sections.find((section) => !section.completed);
  if (!lastCompletedSection) return { path: `/applications/${applicationId}/${LONG_APP_ROUTES.REVIEW}`, sections };

  return {
    path: lastCompletedSection.path,
    hash: lastCompletedSection?.hash,
    sections,
  };
};
