import config from '@config';

export const ldProvider = {
  clientSideID: config.LAUNCH_DARKLY_CLIENT_KEY || '',
  deferInitialization: false,
  diagnosticOptOut: true,
  sendEvents: false,
  flags: {
    restrictClosingDate: false,
  },
};
