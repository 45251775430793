import ErrorModalStyles from './ErrorModal.styles';

type Props = {
  header?: string;
  content?: string;
  'data-testid'?: string;
};

const ErrorModal = ({ header, content, 'data-testid': dataTestId }: Props) => (
  <div data-testid={dataTestId}>
    {header && <ErrorModalStyles.Header>{header}</ErrorModalStyles.Header>}
    {content && <ErrorModalStyles.Content>{content}</ErrorModalStyles.Content>}
  </div>
);

ErrorModal.defaultProps = {
  'data-testid': '',
  header: undefined,
  content: undefined,
};

export default ErrorModal;
