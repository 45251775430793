import AddressForm from '@components/AddressForm/AddressForm';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { AddressDto, DealPurpose, DwellingStatus, PropertyOccupationType } from '@pinecorpca/evergreen';
import Checkbox from '@spruce/Checkbox/Checkbox';
import {
  useAddressHistoriesQuery,
  useAddressHistoryMutationOnPatch,
  useAddressHistoryMutationOnPost,
} from 'hooks/queries/address-histories.hooks';
import { useBorrowerMutationOnPatch } from 'hooks/queries/borrower.hooks';
import { useMortgageAppQuery } from 'hooks/queries/mortgage-application.hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { commonAreAddressesEqual } from 'utils/common.utils';
import { formatAddress } from 'utils/formatAddress.util';
import { useRouter } from 'next/router';
import { Button } from '@pinecorpca/spruce';
import { BackButton } from '@components/index';
import Styled from './CurrentAddress.styled';

type Props = {
  onSubmit: (arg?: any) => void;
  onBack?: () => void;
};

const CurrentAddress = ({ onSubmit, onBack }: Props) => {
  const { t } = useTranslation(NAMESPACE.LONGAPP);
  const methods = useForm();
  const {
    query: { borrowerId },
  } = useRouter();
  const { data: application } = useMortgageAppQuery();
  const { data: addressHistories } = useAddressHistoriesQuery(borrowerId as string);
  const { mutateAsync: updateAddressHistory, isPending: isUpdateAddressLoading } = useAddressHistoryMutationOnPatch();
  const { mutateAsync: createAddressHistory, isPending: isCreateAddressLoading } = useAddressHistoryMutationOnPost();
  const { mutateAsync: updateBorrower, isPending: isUpdateBorrowerLoading } = useBorrowerMutationOnPatch();
  const [sameAddress, setSameAddress] = useState<boolean>();
  const { setValue } = methods;

  const currentAddress = useMemo(() => addressHistories?.find((address) => address.isCurrent), [addressHistories]);

  const primaryAddress = useMemo(() => application?.property?.propertyAddress, [application?.property]);

  const enableSameAddressCheck = useMemo(
    () =>
      (application?.dealPurpose === DealPurpose.SwitchTransfer || application?.dealPurpose === DealPurpose.Refinance) &&
      application?.property?.intendedOccupationType === PropertyOccupationType.OwnerOccupied,
    [application?.dealPurpose, application?.property?.intendedOccupationType]
  );

  useEffect(() => {
    const isEqual = commonAreAddressesEqual(currentAddress?.address, primaryAddress);
    if (enableSameAddressCheck) {
      setSameAddress(isEqual);
    }
  }, [currentAddress?.address, primaryAddress, enableSameAddressCheck]);

  const setAddress = useCallback(
    (isChecked: boolean) => {
      if (isChecked && primaryAddress) {
        setValue('address', primaryAddress);
      } else {
        setValue('address', {
          streetNumber: '',
          streetName: '',
          city: '',
          postalCode: '',
          province: null,
        });
      }
    },
    [primaryAddress, setValue]
  );

  const handleUpdateBorrower = useCallback(
    (path: string, value: string) =>
      updateBorrower({
        borrowerId: borrowerId as string,
        requestBody: [{ op: 'replace', path, value }],
      }),
    [borrowerId, updateBorrower]
  );

  const handleOnSubmit = async (data: Record<string, AddressDto>): Promise<void> => {
    if (!currentAddress) {
      createAddressHistory(
        {
          borrowerId: borrowerId as string,
          addressHistory: { ...data, isCurrent: true },
        },
        {
          onSuccess: async ({ data }) => await handleUpdateBorrower('/contactAddressID', data?.address?.id as string),
        }
      );
    } else {
      const updateAddressResp = await updateAddressHistory({
        addressHistoryId: currentAddress.id as string,
        requestBody: [
          {
            op: 'replace',
            path: '/address',
            value: data?.address,
          },
        ],
      });
      if (updateAddressResp instanceof Error) return;
    }

    if (sameAddress) {
      await handleUpdateBorrower('/dwellingStatus', DwellingStatus.Own);
    }
    onSubmit({});
  };

  const isLoading = useMemo(
    () => [isUpdateAddressLoading, isCreateAddressLoading, isUpdateBorrowerLoading].some((isLoading) => isLoading),
    [isCreateAddressLoading, isUpdateAddressLoading, isUpdateBorrowerLoading]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
        {enableSameAddressCheck && (
          <Checkbox
            style={{ marginBottom: '40px' }}
            id="checkbox-same-address"
            data-testid="checkbox-same-address"
            checked={sameAddress}
            label={t('BORROWER_INFO_CHECKBOX_LABEL', {
              ns: NAMESPACE.ADDITIONAL_BORROWERS,
              address: formatAddress(primaryAddress) ?? '',
            })}
            disabled={isUpdateAddressLoading || isCreateAddressLoading}
            name="sameAddress"
            onChange={(e) => {
              setSameAddress(e.target.checked);
              setAddress(e.target.checked);
            }}
          />
        )}
        <AddressForm
          key="current_address"
          id="current_address"
          fieldName="address"
          address={currentAddress?.address}
          disabled={isUpdateAddressLoading || isCreateAddressLoading || sameAddress}
        />
        <Styled.ButtonWrapper>
          {!!onBack && <BackButton onClick={onBack} visible />}
          <Button style={{ width: '120px' }} data-testid="current-address-cta" type="submit" loading={isLoading}>
            {t('CTA_CONTINUE', { ns: NAMESPACE.DEFAULT })}
          </Button>
        </Styled.ButtonWrapper>
      </form>
    </FormProvider>
  );
};

export default CurrentAddress;
