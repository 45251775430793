import { FunctionComponent, useCallback, useState } from 'react';
import Input from '@spruce/Inputs/Input/Input';
import noop from 'lodash/noop';
import { usePlacesWidget } from 'react-google-autocomplete';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import ProvinceModal from '@components/AddressForm/components/ProvinceModal';
import { enabledProvinces } from '@components/AddressForm/constants/enabled-provinces.constant';
import { Province } from '@pinecorpca/evergreen';
import { parseAddressComponent } from './places.utils';
import snakeCase from 'lodash/snakeCase';
import { NAMESPACE } from '@models/enums';
import config from '@config';
import isObject from 'lodash/isObject';
import { PlacesResult } from './places.models';

type PlacesAutoCompleteProps = {
  label: string;
  name: string;
  placeholder?: string;
  onChange: (place: PlacesResult | null) => void;
  value: string | PlacesResult;
  error: FieldError | undefined;
};

const PlacesAutoComplete: FunctionComponent<PlacesAutoCompleteProps> = ({ label, name, value, onChange, error, placeholder }) => {
  const [toggleProvinceModal, setToggleProvinceModal] = useState(false);
  const { t } = useTranslation();

  const validatePlace = useCallback((place: google.maps.places.PlaceResult): PlacesResult | null => {
    if (!place?.address_components) return null;
    const parsed = parseAddressComponent(place.address_components);
    // google sends province as long name, adding snake case to match our enum
    const snakeCaseProvince = snakeCase(parsed?.province)?.toUpperCase() as Province;
    const invalidProvince = !enabledProvinces.includes(snakeCaseProvince);
    if (invalidProvince) {
      setToggleProvinceModal(true);
      return null;
    }

    return {
      formattedAddress: place.formatted_address as string,
      province: snakeCaseProvince,
      postalCode: parsed?.postalCode,
      city: parsed?.city,
    };
  }, []);

  const { ref: placesRef } = usePlacesWidget({
    apiKey: config.GOOGLE_PLACES_KEY,
    libraries: ['places'],
    onPlaceSelected: (place) => {
      const validatedPlace = validatePlace(place);
      onChange(validatedPlace);
    },
    options: {
      types: ['(regions)'],
      componentRestrictions: { country: 'ca' },
    },
  });

  return (
    <>
      <Input
        autoComplete="off"
        value={isObject(value) ? value?.formattedAddress : value}
        ref={placesRef as any}
        name={name}
        placeholder={placeholder}
        id={name}
        label={label}
        onChange={noop}
        errorMessage={t(error?.message || '', { ns: NAMESPACE.DEFAULT })}
        invalid={!!error}
        data-testid="places-autocomplete-input"
      />
      <ProvinceModal open={toggleProvinceModal} onClose={() => setToggleProvinceModal(false)} />
    </>
  );
};

PlacesAutoComplete.defaultProps = {
  placeholder: '',
};

export default PlacesAutoComplete;
