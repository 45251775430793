import DeletionPopup from '@containers/long-app/components/DeletionPopup/DeletionPopup';
import FormStyles from '@containers/long-app/styles/Form.styles';
import TypeFormsStyles from '@containers/long-app/styles/TypeForms.styles';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { DetailedIncomeDto, Employment, EmploymentType, IncomeType } from '@pinecorpca/evergreen';
import { useTranslation } from 'next-i18next';
import { forwardRef, useCallback } from 'react';
import EmploymentForm from '../employmentForm/EmploymentForm';
import OtherForm from '../otherForm/OtherForm';
import RentalForm from '../rentalForm/RentalForm';
import SelfEmployedForm from '../selfEmploymentForm/SelfEmployedForm';

type Props = {
  index: number;
  income: DetailedIncomeDto;
  onDelete: (index: number, incomeId: string) => Promise<any>;
  isDeleting: boolean;
};

const IncomeForm = forwardRef<HTMLDivElement, Props>(({ index, income, onDelete, isDeleting }, ref) => {
  const { t } = useTranslation(NAMESPACE.LONGAPP);

  const handleDelete = () => onDelete(index, income?.income?.incomeId ?? '');

  const getFormComponent = useCallback(() => {
    switch (income.incomeType) {
      case IncomeType.Employment:
        return EmploymentForm;
      case IncomeType.Selfemployment:
        return SelfEmployedForm;
      case IncomeType.Rental:
        return RentalForm;
      default:
        return OtherForm;
    }
  }, [income.incomeType]);

  const getLabel = () => {
    switch (income.incomeType) {
      case IncomeType.Employment:
        if ((income.income as Employment).employmentType === EmploymentType.FullTime) {
          return t(`INCOMES_EMPLOYMENT_FULLTIME${income.current ? '' : '_PAST'}`);
        }
        return t(`INCOMES_EMPLOYMENT_PARTTIME${income.current ? '' : '_PAST'}`);
      case IncomeType.Selfemployment:
        return t(`INCOMES_SELFEMPLOYED${income.current ? '' : '_PAST'}`);
      case IncomeType.Rental:
        return t('INCOMES_RENTAL');
      default:
        return t('INCOMES_OTHER');
    }
  };

  const renderForm = useCallback(
    (income: DetailedIncomeDto) => {
      const SpecificForm = getFormComponent() as unknown as ({ ...props }: Record<string, any>) => JSX.Element;
      return <SpecificForm income={income} index={index} />;
    },
    [getFormComponent, index]
  );

  return (
    <TypeFormsStyles.Container data-testid={`checkbox_${income?.income?.id}`} ref={ref} tabIndex={0}>
      <FormStyles.FormTitleWrapper>
        <FormStyles.FormTitle>{getLabel()}</FormStyles.FormTitle>
        <DeletionPopup
          data-testid={`delete_${income?.income?.id}`}
          isLoading={isDeleting}
          title={t('INCOMES_DELETE_ENTRY')}
          onConfirm={() => handleDelete()}
        />
      </FormStyles.FormTitleWrapper>
      {renderForm(income)}
    </TypeFormsStyles.Container>
  );
});

IncomeForm.displayName = 'IncomeForm';

export default IncomeForm;
