import { API } from '@models/enums/api.enum';
import { LoanStage, TasksControllerApiApiTasksTaskIdPostRequest as PostTaskRequest, TaskResponseDto } from '@pinecorpca/evergreen';
import { taskApi } from 'api/evergreen';
import { AxiosRequestConfig } from 'axios';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMortgageAppQuery } from './mortgage-application.hooks';
import { useRouter } from 'next/router';
import { DetailedTaskResponseDto } from 'hooks/models/tasks.model';

export const useTasksQuery = (): UseQueryResult<DetailedTaskResponseDto[]> => {
  const {
    query: { applicationId },
  } = useRouter();
  const { data: app } = useMortgageAppQuery();

  return useQuery({
    queryKey: [API.TASKS, applicationId as string],
    queryFn: async () => {
      const { data: tasks } = await taskApi.apiPublicTasksGet({
        applicationId: applicationId as string,
      });
      // persist the previous status to display a the completed task temporarily to the user
      // see https://pinecanada.atlassian.net/browse/CUS-3156 "To-do Section"
      return tasks?.map((task) => ({ ...task, previousStatus: task.status })) || [];
    },
    enabled: !!applicationId && app?.loanStage !== LoanStage.AppCreated,
  });
};

export const useDocumentTaskMutationOnPost = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();
  return useMutation({
    mutationFn: (payload: { request: PostTaskRequest; options?: AxiosRequestConfig }) =>
      taskApi.apiTasksV2TaskIdPost(payload.request, payload.options),
    onSuccess: ({ data: updateTask }) => {
      queryClient.setQueryData([API.TASKS, applicationId as string], (current: TaskResponseDto[] | undefined) => {
        const tasks = current?.map((task) =>
          task.id === updateTask.id
            ? {
                ...updateTask,
                previousStatus: task.status,
              }
            : task
        );
        return tasks;
      });
    },
  });
};

export const useFormTaskMutationOnPost = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();
  return useMutation({
    mutationFn: (payload: { request: PostTaskRequest; options?: AxiosRequestConfig }) =>
      taskApi.apiTasksV2TaskIdFormPost(payload.request, payload.options),
    onSuccess: ({ data: updateTask }) => {
      queryClient.setQueryData([API.TASKS, applicationId as string], (current: TaskResponseDto[] | undefined) => {
        const tasks = current?.map((task) =>
          task.id === updateTask.id
            ? {
                ...updateTask,
                previousStatus: task.status,
              }
            : task
        );
        return tasks;
      });
    },
  });
};
