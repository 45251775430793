import AddressForm from '@components/AddressForm/AddressForm';
import FormStyles from '@containers/long-app/styles/Form.styles';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { DetailedIncomeDto, PropertyType } from '@pinecorpca/evergreen';
import DatePicker from '@components/DatePicker/DatePicker';
import Dropdown from '@spruce/Dropdown/Dropdown';
import CurrencyInput from '@spruce/Inputs/Currency/CurrencyInput';
import { subYears } from 'date-fns';
import { PropertyTypeOptions } from 'containers/short-app/models/intentions.model';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { MAINTENANCE_FEE_MAX_VALUE } from './rental.constraints';

type Props = {
  income: DetailedIncomeDto;
  index: number;
};

const RentalForm = ({ income, index }: Props): JSX.Element => {
  const { control } = useFormContext();
  const { t } = useTranslation(NAMESPACE.LONGAPP);

  const propertyType = useWatch({
    control,
    name: `incomes.${index}.income.property.propertyType`,
  });

  return (
    <>
      <FormStyles.FormTitle>
        <span className="title">{t('INCOMES_RENTAL_PROPERTY_DETAILS')}</span>
      </FormStyles.FormTitle>
      <FormStyles.SubFormWrapper>
        <Controller
          control={control}
          name={`incomes.${index}.income.property.propertyType`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_RENTAL_TYPE_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <Dropdown
              name={name}
              id={name}
              label={t('INCOMES_RENTAL_PROPERTY_TYPE')}
              defaultValue={value ?? ''}
              options={PropertyTypeOptions?.map((option) => ({
                ...option,
                label: t(option.label as string),
              }))}
              data-testid={`${income.income?.incomeId}.propertyType`}
              invalid={!!error}
              errorMessage={error?.message}
              onChange={onChange}
              ref={ref}
            />
          )}
        />
        <FormStyles.FormRow>
          <AddressForm
            id={`incomes.${index}.income.property.propertyAddress`}
            fieldName={`incomes.${index}.income.property.propertyAddress`}
          />
        </FormStyles.FormRow>
      </FormStyles.SubFormWrapper>

      <FormStyles.FormSubtitle>{t('INCOMES_RENTAL_PROPERTY_INCOME')}</FormStyles.FormSubtitle>
      <FormStyles.SubFormWrapper>
        <Controller
          control={control}
          name={`incomes.${index}.income.propertyValue`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_RENTAL_VALUE_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <CurrencyInput
              name={name}
              id={name}
              label={t('INCOMES_RENTAL_PROPERTY_VALUE')}
              data-testid={`${income.income?.incomeId}.propertyValue`}
              placeholder="$0"
              value={value ?? ''}
              invalid={!!error}
              errorMessage={error?.message}
              onChange={onChange}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`incomes.${index}.years.0.annualAmount`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_RENTAL_RENT_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <CurrencyInput
              name={name}
              id={name}
              label={t('INCOMES_RENTAL_MONTHLY_RENT')}
              data-testid={`${income.income?.incomeId}.annualAmount`}
              placeholder="$0"
              value={value ?? ''}
              invalid={!!error}
              errorMessage={error?.message}
              onChange={onChange}
              ref={ref}
            />
          )}
        />
        {[PropertyType.RowHousing, PropertyType.ApartmentHighRise].includes(propertyType) && (
          <Controller
            control={control}
            name={`incomes.${index}.income.monthlyExpenses`}
            rules={{
              max: {
                value: MAINTENANCE_FEE_MAX_VALUE,
                message: t('INCOMES_RENTAL_MAINTENANCE_FEE_EXCEED'),
              },
            }}
            render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
              <CurrencyInput
                name={name}
                id={name}
                label={t('INCOMES_RENTAL_MAINTENANCE_FEE')}
                data-testid={`${income.income?.incomeId}.maintenanceFee`}
                value={value ?? ''}
                placeholder="$0"
                invalid={!!error}
                errorMessage={error?.message}
                onChange={onChange}
                ref={ref}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name={`incomes.${index}.startDate`}
          rules={{
            required: {
              value: true,
              message: t('RENTAL_PROPERTY_START_DATE_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <DatePicker
              name={name}
              id={name}
              ref={ref}
              label={t('RENTAL_PROPERTY_START_DATE')}
              value={value}
              to={new Date()}
              from={subYears(new Date(), 100)}
              picker="month"
              invalid={!!error?.message}
              errorMessage={error?.message ?? ''}
              onChange={onChange}
            />
          )}
        />
      </FormStyles.SubFormWrapper>
    </>
  );
};

export default RentalForm;
