export enum ROUTES {
  ROOT = '/',
  BORROWER_PORTAL = 'borrower',
  CUSTOMER_PORTAL = 'customer-portal',
  GET_STARTED = 'start',
  CREDIT_CONSENT = 'credit-consent',
  OAUTH_CALLBACK = 'oauth_callback',
  LOGOUT_CALLBACK = 'logout_callback',
  LOGIN = 'login',
  NOT_FOUND = '/404',
  APPLICATIONS = 'applications',
  BORROWERS = 'borrowers',
}

export enum CUSTOMER_PORTAL_ROUTES {
  DASHBOARD = 'dashboard',
  TASKS = 'tasks',
  DOCUMENTS = 'documents',
  SERVICING = 'servicing',
  CONTACT = 'contact',
  CREATE = 'create',
  BEGIN = 'begin',
}

export enum LONG_APP_ROUTES {
  ASSETS = 'assets',
  INCOMES = 'incomes',
  APPLICANT_INFO = 'applicant-info',
  PROPERTY = 'subject-property',
  REVIEW = 'review',
  SUMMARY = 'summary',
  RATES = 'rates',
  ADDITIONAL_BORROWERS = 'additional-borrowers',
  BORROWERS_INFO = 'borrower-info',
}

export enum SHORT_APP_ROUTES {
  REGISTER = 'register',
}

export enum SERVICING_ROUTES {
  details = 'details',
}
