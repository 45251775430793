import FormStyles from '@containers/long-app/styles/Form.styles';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { DetailedIncomeDto } from '@pinecorpca/evergreen';
import DatePicker from '@components/DatePicker/DatePicker';
import CurrencyInput from '@spruce/Inputs/Currency/CurrencyInput';
import Input from '@spruce/Inputs/Input/Input';
import { subYears } from 'date-fns';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { formatDate } from '../../../utils/incomes.utils';
import { isAfterOrEqual } from 'utils/date.utils';

type Props = {
  income: DetailedIncomeDto;
  index: number;
};

const EmploymentForm = ({ income, index }: Props): JSX.Element => {
  const { control, getValues } = useFormContext();

  const isCurrent = useWatch({
    control,
    name: `incomes.${index}.current`,
  });

  const { t } = useTranslation(NAMESPACE.LONGAPP);

  return (
    <>
      <FormStyles.FormTitle>
        <span className="title">{t('INCOMES_EMPLOYMENT_EMPLOYMENT_DETAILS')}</span>
      </FormStyles.FormTitle>
      <FormStyles.SubFormWrapper>
        <Controller
          control={control}
          name={`incomes.${index}.income.employerName`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_EMPLOYMENT_NAME_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <Input
              name={name}
              id={name}
              label={t('INCOMES_EMPLOYMENT_NAME')}
              placeholder={t('INCOMES_EMPLOYMENT_NAME_PLACEHOLDER')}
              data-testid={`${income.income?.incomeId}.employmentName`}
              onChange={onChange}
              value={value}
              invalid={!!error}
              errorMessage={error?.message}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`incomes.${index}.income.jobTitle`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_EMPLOYMENT_TITLE_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <Input
              name={name}
              id={name}
              label={t('INCOMES_EMPLOYMENT_JOB_TITLE')}
              placeholder={t('INCOMES_EMPLOYMENT_JOB_TITLE_PLACEHOLDER')}
              data-testid={`${income.income?.incomeId}.jobTitle`}
              value={value}
              invalid={!!error}
              errorMessage={error?.message}
              onChange={onChange}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`incomes.${index}.startDate`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_EMPLOYMENT_START_DATE_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <DatePicker
              id={name}
              name={name}
              ref={ref}
              label={t('INCOMES_EMPLOYMENT_START_DATE')}
              value={value}
              to={new Date()}
              from={subYears(new Date(), 100)}
              picker="month"
              invalid={!!error?.message}
              errorMessage={error?.message ?? ''}
              onChange={onChange}
            />
          )}
        />
        {!isCurrent && (
          <Controller
            control={control}
            name={`incomes.${index}.endDate`}
            rules={{
              required: {
                value: true,
                message: t('INCOMES_EMPLOYMENT_END_DATE_REQUIRED'),
              },
              validate: (value) => {
                if (!value) return undefined;
                const startDate = getValues(`incomes.${index}.startDate`);
                return isAfterOrEqual(new Date(value), new Date(startDate)) || t('INCOMES_INVALID_END_DATE');
              },
            }}
            render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
              <DatePicker
                id={name}
                name={name}
                ref={ref}
                label={t('INCOMES_EMPLOYMENT_END_DATE')}
                value={value}
                to={new Date()}
                from={formatDate(getValues(`incomes.${index}.endDate`)) ?? subYears(new Date(), 100)}
                picker="month"
                invalid={!!error?.message}
                errorMessage={error?.message ?? ''}
                onChange={onChange}
              />
            )}
          />
        )}
      </FormStyles.SubFormWrapper>
      <FormStyles.FormSubtitle>{t('INCOMES_EMPLOYMENT_INCOME')}</FormStyles.FormSubtitle>
      <FormStyles.SubFormWrapper>
        <Controller
          control={control}
          name={`incomes.${index}.years.0.annualAmount`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_EMPLOYMENT_BASE_REQUIRED'),
            },
          }}
          render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
            <CurrencyInput
              name={name}
              id={name}
              label={t('INCOMES_EMPLOYMENT_ANNUAL_BASE')}
              data-testid={`${income.income?.incomeId}.annualBase`}
              value={value}
              invalid={!!error}
              errorMessage={error?.message}
              placeholder="$0"
              onChange={onChange}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`incomes.${index}.years.0.bonus`}
          render={({ field: { name, value, onChange, ref } }) => (
            <CurrencyInput
              name={name}
              id="INCOMES_EMPLOYMENT_ANNUAL_BONUS"
              label={t('INCOMES_EMPLOYMENT_ANNUAL_BONUS')}
              data-testid={`${income.income?.incomeId}.annualBonus`}
              value={value}
              onChange={onChange}
              placeholder="$0"
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`incomes.${index}.years.0.overtime`}
          render={({ field: { name, value, onChange, ref } }) => (
            <CurrencyInput
              name={name}
              id={name}
              label={t('INCOMES_EMPLOYMENT_ANNUAL_OVERTIME')}
              data-testid={`${income.income?.incomeId}.annualOvertime`}
              value={value}
              onChange={onChange}
              placeholder="$0"
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`incomes.${index}.years.0.commission`}
          render={({ field: { name, value, onChange, ref } }) => (
            <CurrencyInput
              name={name}
              id={name}
              label={t('INCOMES_EMPLOYMENT_ANNUAL_COMMISSION')}
              data-testid={`${income.income?.incomeId}.annualCommission`}
              value={value}
              onChange={onChange}
              placeholder="$0"
              ref={ref}
            />
          )}
        />
      </FormStyles.SubFormWrapper>
    </>
  );
};

export default EmploymentForm;
