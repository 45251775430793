import AddressForm from '@components/AddressForm/AddressForm';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { AddressDto, BorrowerType } from '@pinecorpca/evergreen';
import Checkbox from '@spruce/Checkbox/Checkbox';
import { useAddressHistoriesQuery } from 'hooks/queries/address-histories.hooks';
import { useMortgageAppQuery } from 'hooks/queries/mortgage-application.hooks';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { areAddressesEqual, formatAddress } from 'utils/formatAddress.util';
import { Button } from '@pinecorpca/spruce';
import Styled from './BorrowerAddress.styled';

type BorrowerAddressForm = {
  sameAddress: boolean;
  borrowerAddress: AddressDto;
};

interface BorrowerAddressProps {
  onSubmit: ({ address }: { address: AddressDto }) => void;
  submitting?: boolean;
}

const BorrowerAddress = ({ onSubmit, submitting }: BorrowerAddressProps) => {
  const {
    query: { borrowerId },
  } = useRouter();
  const { t } = useTranslation();
  const { data: borrowerAddressHistories } = useAddressHistoriesQuery(borrowerId as string);
  const { data: application } = useMortgageAppQuery();
  const [sameAddress, setSameAddress] = useState<boolean>();
  const [borrowerAddress, setBorrowerAddress] = useState<AddressDto | undefined>(undefined);
  const methods = useForm<BorrowerAddressForm>();
  const { setValue, handleSubmit, register } = methods;

  const mainAddress = useMemo(() => {
    const mainApplicant = application?.borrowers?.find((borrower) => borrower.borrowerType === BorrowerType.Primary);
    return mainApplicant?.addressHistories?.find((history) => history.isCurrent)?.address;
  }, [application?.borrowers]);

  useEffect(() => {
    const address = borrowerAddressHistories?.find(({ isCurrent }) => isCurrent)?.address;
    if (address) {
      setValue('borrowerAddress', address);
      setBorrowerAddress(address);
    }
  }, [borrowerAddressHistories, setValue]);

  useEffect(() => {
    const isEqual = areAddressesEqual(borrowerAddress, mainAddress);
    setSameAddress(isEqual);
  }, [borrowerAddress, mainAddress]);

  const setAddress = useCallback(
    (isChecked: boolean) => {
      if (isChecked && mainAddress) {
        setBorrowerAddress(mainAddress);
        setValue('borrowerAddress', mainAddress);
      } else {
        setBorrowerAddress(undefined);
        setValue('borrowerAddress', {
          streetNumber: '',
          streetName: '',
          city: '',
          postalCode: '',
          province: undefined,
        });
      }
    },
    [mainAddress, setValue]
  );

  const handleOnSubmit = (data: BorrowerAddressForm) => {
    const address = (sameAddress ? mainAddress : data.borrowerAddress) as AddressDto;
    onSubmit({ address });
  };

  return (
    <FormProvider {...methods}>
      <Styled.Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Checkbox
          id="checkbox-same-address"
          data-testid="checkbox-same-address"
          checked={sameAddress}
          label={t('BORROWER_INFO_CHECKBOX_LABEL', {
            ns: NAMESPACE.ADDITIONAL_BORROWERS,
            address: formatAddress(mainAddress) ?? '',
          })}
          disabled={submitting}
          {...register('sameAddress', {
            onChange: (e) => {
              setSameAddress(e.target.checked);
              setAddress(e.target.checked);
            },
          })}
        />
        <AddressForm disabled={sameAddress} id="borrower-address" fieldName="borrowerAddress" address={borrowerAddress} />
        <Button
          style={{ alignSelf: 'flex-end', width: '155px' }}
          data-testid="button-borrower-address-cta"
          type="submit"
          loading={submitting}
        >
          {t('CTA_SAVE', { ns: NAMESPACE.DEFAULT })}
        </Button>
      </Styled.Form>
    </FormProvider>
  );
};

export default BorrowerAddress;
